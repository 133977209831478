import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import { formatISODate } from '../../../utils';
import { PageBodySection } from '../../common/PageBodySection';
import { useTareaState } from '../TareaView';
import { JornadaNotas } from './JornadaNotas';
import NotificarIncidencia from './NotificarIncidencia';

const useStyles = makeStyles((theme) => ({
    showHistory: {
        fontSize: 14,
        fontWeight: 500,
        color: '#45486E',
        padding: theme.spacing(2),
        borderTop: '1px solid #E2E4E9',
        borderBottom: '1px solid #E2E4E9',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            color: 'inherit',
        },
    },
    emptyHistoryText: {
        color: '#818cae',
        fontSize: 12,
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const useInfoStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        '&:last-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#818CAE',
        '& span': {
            fontWeight: 'normal',
        },
    },
    info: {
        fontSize: 10,
        color: '#818CAE',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& svg': {
            fontSize: 13,
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const TareasNotas = ({ tareasNotas, setTareasNotas, setAddNotaView }) => {
    const classes = useStyles();
    const infoClasses = useInfoStyles();
    const [showHistory, setShowHistory] = useState(false);
    const [notificarIncidenciaJornadaId, setNotificarIncidenciaJornadaId] = useState(false);
    const snackbar = useSnackbar();
    const { setTarea } = useTareaState();

    const {
        userInfo: {
            preferencias: { enviar_email_incidencia: enviarEmail },
        },
    } = useAuthState();

    const {
        id,
        tarea: { jornadas },
    } = useTareaState();

    const todayStr = formatISODate(new Date());
    const hasToday = jornadas.findIndex((jornada) => jornada.propia && jornada.fecha === todayStr) >= 0;

    useEffect(() => {
        if (!showHistory || tareasNotas) return;

        tareasProvider.actionOnId(id, 'historico_notas', null, { method: 'get' }).then(setTareasNotas);
    }, [showHistory]);

    return (
        <>
            <NotificarIncidencia
                open={Boolean(notificarIncidenciaJornadaId)}
                onClose={() => setNotificarIncidenciaJornadaId(false)}
                onAccept={() => {
                    return tareasProvider
                        .notificarIncidencia(notificarIncidenciaJornadaId)
                        .then(() => snackbar.showMessage('Se ha enviado la notificación con éxito'));
                }}
            />
            {!hasToday && (
                <PageBodySection className={infoClasses.root}>
                    <Typography className={infoClasses.title}>Hoy</Typography>
                    <Typography className={infoClasses.info}>
                        <InfoIcon /> Registra el tiempo para poder añadir las observaciones de hoy.
                    </Typography>
                </PageBodySection>
            )}
            {jornadas
                .filter((jornada) => jornada.propia || jornada.notas.length > 0 || jornada.fotos.length > 0)
                .map((jornada, i) => (
                    <JornadaNotas
                        key={i}
                        jornada={jornada}
                        setAddNotaView={() => setAddNotaView(jornada)}
                        onSaveNota={(updatedNota) => {
                            if (updatedNota.incidencia && enviarEmail) setNotificarIncidenciaJornadaId(jornada.id);

                            setTarea((tarea) => {
                                return {
                                    ...tarea,
                                    jornadas: tarea.jornadas.map((oldJornada) =>
                                        oldJornada.id === jornada.id
                                            ? {
                                                  ...oldJornada,
                                                  notas: [updatedNota],
                                              }
                                            : oldJornada,
                                    ),
                                };
                            });
                        }}
                    />
                ))}
            <Button className={classes.showHistory} onClick={() => setShowHistory((show) => !show)}>
                <Typography>HISTORIAL DE OBSERVACIONES</Typography>
                {showHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <Collapse in={showHistory}>
                <div>
                    {tareasNotas && tareasNotas.length > 0 ? (
                        tareasNotas.map((tareaNotas, i) => (
                            <JornadaNotas
                                id={i}
                                key={i}
                                jornada={tareaNotas}
                                setAddNotaView={setAddNotaView}
                                style={{ backgroundColor: '#E4E7F1' }}
                                disabled={true}
                            />
                        ))
                    ) : (
                        <Typography className={classes.emptyHistoryText}>No hay observaciones que mostrar</Typography>
                    )}
                </div>
            </Collapse>
        </>
    );
};

TareasNotas.propTypes = {
    setAddNotaView: PropTypes.any,
    setTareasNotas: PropTypes.any,
    tareasNotas: PropTypes.any,
};
