import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            background: theme.palette.neutral.dark,
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 16px 24px 16px',
            flex: (props) => (props.fill ? 1 : null),
        },
        titleBar: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 48,
            maxHeight: 48,
            '& .MuiTypography-root': {
                flex: 1,
                fontSize: 18,
                textAlign: 'left',
                fontWeight: 500,
                // textTransform: 'capitalize',
            },
            '& .MuiIconButton-root': {
                color: 'white',
                padding: 6,
            },
            '&>.MuiIconButton-root:first-child': {
                marginLeft: -8,
            },
            '&>.MuiIconButton-root:last-child': {
                marginRight: -8,
            },
        },
    }),
    { name: 'PageHeader' },
);

export function PageHeader({ title, startButton, endButton, children, onTitleClick, fill = false, className }) {
    const classes = useStyles({ fill });

    return (
        <div className={classnames(classes.root, className)}>
            <div className={classes.titleBar}>
                {startButton && React.cloneElement(startButton)}
                <Typography variant='h1' onClick={onTitleClick || (() => {})}>
                    {title}
                </Typography>
                {endButton && React.cloneElement(endButton)}
            </div>
            {children}
        </div>
    );
}

PageHeader.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
    endButton: PropTypes.any,
    fill: PropTypes.bool,
    onTitleClick: PropTypes.any,
    startButton: PropTypes.any,
    title: PropTypes.any,
};
