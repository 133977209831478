import DataProvider from './DataProvider';
import { createTiempo } from './tareas-functions';

export const empty = {
    nombre: '',
    email: '',
    telefono: '',
};

export function getInitials(name) {
    const rgx = /(\p{L}{1})\p{L}+/gu;

    let initials = [...name.matchAll(rgx)] || [];

    initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();

    return initials;
}

class OperariosProvider extends DataProvider {
    constructor() {
        super('operarios', empty);
    }

    getResumenHorasMes = async (year, month, week = null) => {
        let url = `resumen-horas/${year}/${month}`;
        if (week) url = `${url}/${week}`;

        return this.getAll(url, null, { method: 'get' }).then(
            ({ horas_laborables: laborables, minutos_trabajados: trabajadas, minutos_extras: extras }) => ({
                laborables,
                trabajadas: createTiempo(trabajadas),
                extras: createTiempo(extras),
            }),
        );
    };
}

export const dataProvider = new OperariosProvider();
