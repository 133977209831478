import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import debounce from '@material-ui/core/utils/debounce';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { tareasProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import { PageBodySection } from '../../common/PageBodySection';
import { SwitchWithLabel } from '../../common/SwitchWithLabel';
import { useTareaState } from '../TareaView';

const Input = withStyles({
    root: {
        color: '#818CAE',
        fontSize: 13,
        height: '100%',
    },
    inputMultiline: {
        height: '100%',
    },
})(InputBase);

const useStyles = makeStyles(
    (theme) => ({
        notas: {
            backgroundColor: '#fff',
            borderRadius: 8,
            padding: '8px 16px',
            position: 'relative',
            minHeight: 100,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(2),
            flex: 1,
        },
        incidencia: {
            marginBottom: theme.spacing(2),
        },
        saving: {
            fontSize: 12,
            color: '#818CAE',
            gap: `${theme.spacing(1)}px`,
            display: 'flex',
            alignItems: 'center',
        },
        error: {
            color: '#F24822',
        },
    }),
    { name: 'AddNota' },
);

export const AddNota = ({ id, currentNotas, onSave }) => {
    const classes = useStyles();
    const [notas, setNotas] = useState(currentNotas?.texto ?? '');
    const [incidencia, setIncidencia] = useState(currentNotas?.incidencia ?? false);
    const [initialNotas] = useState(currentNotas?.texto ?? '');
    const [saving, setSaving] = useState(null);
    const [error, setError] = useState(false);
    const {
        viewParams: { jornada },
    } = useTareaState();
    const {
        userInfo: {
            preferencias: { usar_incidencias: usarIncidencias },
        },
    } = useAuthState();

    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef.current) return;

        inputRef.current.focus();
        inputRef.current.setSelectionRange(inputRef.current.value.length, inputRef.current.value.length);
    }, [inputRef]);

    const changed = notas !== initialNotas;

    const saveNotas = useCallback(
        (notas) =>
            tareasProvider
                .updateJornadaNotas(jornada.id, notas, incidencia)
                .then((updatedNotas) => {
                    setError(false);
                    onSave(updatedNotas);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setSaving(false)),
        [id, onSave, incidencia],
    );

    const debouncedSaveNotas = useCallback(debounce(saveNotas, 1000), [saveNotas]);

    function changeNotas(value) {
        setSaving(true);
        setError(false);
        setNotas(value);
        debouncedSaveNotas(value);
    }

    return (
        <PageBodySection>
            <div className={classes.notas}>
                <Input
                    fullWidth
                    multiline
                    rows={6}
                    value={notas}
                    onChange={(ev) => {
                        changeNotas(ev.target.value);
                    }}
                    inputRef={inputRef}
                />
                <div
                    style={{
                        position: 'absolute',
                        bottom: 8,
                        right: 8,
                        display: 'flex',
                    }}
                >
                    {!changed && (
                        <IconButton size='small' onClick={() => changeNotas('')}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            </div>
            {usarIncidencias && (
                <SwitchWithLabel
                    onChange={(ev) => {
                        const newIncidenciaValue = ev.target.checked;
                        tareasProvider
                            .updateJornadaNotas(jornada.id, notas, newIncidenciaValue)
                            .then((updatedNotas) => {
                                setIncidencia(newIncidenciaValue);
                                setError(false);
                                onSave(updatedNotas);
                            })
                            .catch(() => {
                                setError(true);
                            })
                            .finally(() => setSaving(false));
                    }}
                    checked={incidencia}
                    label='Marcar como incidencia'
                    className={classes.incidencia}
                />
            )}
            {saving !== null && (
                <>
                    <div className={clsx(classes.saving, { [classes.error]: error })}>
                        {saving && (
                            <>
                                <CircularProgress size={16} /> Guardando cambios...
                            </>
                        )}
                        {!saving && error && (
                            <>
                                <CloseIcon fontSize='small' /> Ha ocurrido un error al guardar
                            </>
                        )}
                        {!saving && !error && (
                            <>
                                <DoneIcon fontSize='small' /> Guardado
                            </>
                        )}
                    </div>
                </>
            )}
        </PageBodySection>
    );
};

AddNota.propTypes = {
    currentNotas: PropTypes.any,
    id: PropTypes.any,
    onSave: PropTypes.any,
};
