import { ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { materialesProvider, preparacionesMaterialProvider } from '../../../api';
import useAuthState from '../../../contexts/AuthState';
import Button from '../../common/Button';
import ListView from '../../common/ListView';
import { PageBodySection } from '../../common/PageBodySection';
import { useTareaState } from '../TareaView';

const useStyles = makeStyles(
    (theme) => ({
        viewSelector: {
            paddingBottom: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            borderBottom: '0.5px solid #818cae60',
        },
    }),
    { name: 'SelectMaterialView' },
);

export const MATERIAL_INTERNO = 'material-interno';
export const PREPARACION_MATERIAL = 'preparacion-material';

export function SelectMaterialView({ onChange, onClose, currentValue }) {
    const classes = useStyles();
    const [materiales, setMateriales] = useState(null);
    const [materialesPreparacion, setMaterialesPreparacion] = useState([]);
    const [view, setView] = useState(MATERIAL_INTERNO);
    const { id: tareaId } = useTareaState();

    const {
        userInfo: {
            preferencias: { usar_preparacion_materiales_lista: usarPreparacionMaterialesLista = false } = {},
        } = {},
    } = useAuthState();

    useEffect(() => {
        Promise.all([
            materialesProvider.getAsOptions(),
            preparacionesMaterialProvider.getMaterialesPreparacion(tareaId),
        ]).then(([materiales, materialesPreparacion]) => {
            setMateriales(materiales);
            setMaterialesPreparacion(materialesPreparacion);

            setView(materialesPreparacion.length > 0 ? PREPARACION_MATERIAL : MATERIAL_INTERNO);

            setTimeout(() => {
                document.querySelector('#global-filter-input').focus();
            }, 200);
        });
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Descripcion',
                accessor: 'descripcion',
            },
        ],
        [],
    );

    return (
        materiales && (
            <ListView
                title='Seleccionar material'
                onClose={onClose}
                setFieldValue={onChange}
                currentValue={currentValue && { id: currentValue.material_interno_id }}
                items={view === MATERIAL_INTERNO ? materiales : materialesPreparacion}
                columns={columns}
                searchPlaceholder='Seleccionar material'
                // canAdd
                multiple={currentValue.descripcion === ''}
            >
                {usarPreparacionMaterialesLista && materialesPreparacion.length > 0 && (
                    <PageBodySection className={classes.viewSelector}>
                        <ButtonGroup size='small'>
                            <Button
                                color={view === PREPARACION_MATERIAL ? 'info' : 'normal'}
                                onClick={() => setView(PREPARACION_MATERIAL)}
                            >
                                Material preparado
                            </Button>
                            <Button
                                color={view === MATERIAL_INTERNO ? 'info' : 'normal'}
                                onClick={() => setView(MATERIAL_INTERNO)}
                            >
                                Todo el material
                            </Button>
                        </ButtonGroup>
                    </PageBodySection>
                )}
            </ListView>
        )
    );
}

SelectMaterialView.propTypes = {
    currentValue: PropTypes.any,
    onChange: PropTypes.any,
    onClose: PropTypes.any,
};
